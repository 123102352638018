import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Box, Button, Skeleton } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';
import type { FavoriteSubregions } from 'types/subregion';
import { subregionForecastPath } from 'utils/urls';

import styles from './SubregionForecastButtonList.module.scss';

const SubregionForecastButtonList: React.FC<{
  isSubregionListVisible?: boolean;
  subregions?: FavoriteSubregions;
  activeSubregion?: string;
}> = ({ activeSubregion, isSubregionListVisible, subregions }) => {
  const { query } = useRouter();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const firstDisabledButtonRef = useRef<HTMLLIElement>(null);

  const isActiveSubregions = query?.subregions?.toString() === 'true' || isSubregionListVisible;
  const isNative = query?.native?.toString() === 'true';

  const subregionListItems = useMemo(
    () =>
      subregions?.map((subregion) => {
        const isDisabled = subregion._id === activeSubregion;
        const href = subregionForecastPath({
          isNative,
          subregionId: subregion._id,
          subregionName: subregion.name,
          subregions: isActiveSubregions,
        });
        return (
          <li key={subregion._id} ref={isDisabled ? firstDisabledButtonRef : null}>
            <Button
              color="dark"
              component={WavetrakLink}
              data-testid="subregion-forecast-button-list-button"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={isDisabled}
              href={href}
              size="small"
              variant="primary"
            >
              {subregion.name}
            </Button>
          </li>
        );
      }),
    [activeSubregion, isActiveSubregions, isNative, subregions],
  );

  useEffect(() => {
    if (
      isActiveSubregions &&
      subregions &&
      scrollWrapperRef.current &&
      firstDisabledButtonRef.current &&
      firstDisabledButtonRef.current.offsetLeft + firstDisabledButtonRef.current.offsetWidth >=
        scrollWrapperRef.current.offsetLeft + scrollWrapperRef.current.offsetWidth
    ) {
      // offset 10% of the width, which is width the right mask fade effect
      const offsetRight = scrollWrapperRef.current.offsetWidth * 0.1;
      scrollWrapperRef.current.scrollTo?.({
        left:
          firstDisabledButtonRef.current.offsetLeft +
          firstDisabledButtonRef.current.offsetWidth +
          offsetRight -
          (scrollWrapperRef.current.offsetLeft + scrollWrapperRef.current.offsetWidth),
        behavior: 'smooth',
      });
    }
  }, [isActiveSubregions, subregions]);

  if (!isActiveSubregions) return null;

  if (!subregions?.length) {
    return (
      <Box className={styles.linkListLoader} data-testid="subregion-forecast-button-list-loading">
        <Skeleton animation="wave" variant="rectangular" width="100%" height={32} />
      </Box>
    );
  }

  return (
    <Box
      className={styles.scrollXWrapper}
      data-testid="subregion-forecast-button-list"
      ref={scrollWrapperRef}
    >
      <ul className={classNames(styles.linkList)}>{subregionListItems}</ul>
    </Box>
  );
};

export default SubregionForecastButtonList;
